import { getCurrentLanguage } from "../../helper/localstorage";

const NavButton = ({
  text,
  fontSize,
  englishFont,
  border,
  bold,
  backgroundColor,
  component,
}) => {
  return (
    <div
      className={`flex flex-nowrap px-8 md:mx-2 xl:mx-2 mx-1 ${
        border ? "border-2" : null
      } rounded-full text-white cursor-pointer h-12 ${backgroundColor}`}
    >
      {component ? (
        <>{component}</>
      ) : (
        <span
          className={`w-full h-full flex items-center justify-center text-[${fontSize}] font-${bold} ${
            getCurrentLanguage() === "ka"
            // ? 'font-["TBCContracticaCAPS"] pt-[5px]'
            // : `font-["${englishFont}"]`
          } `}
        >
          {text}
        </span>
      )}
    </div>
  );
};

export default NavButton;
