import FirstSection from "./first-section";
import ThirdSection from "./third-section";
import SecondSection from "./second-section";
import FourthSection from "./fourth-section";
import FifthSection from "./fifth-section";
import Sixthsection from "./sixth-section";
import { useContext } from "react";
import ThemeContext from "../../../context/theme";

const HeroComponent = ({ lang, setLang }) => {
  const theme = useContext(ThemeContext);

  return (
    <>
      <div className="w-full h-full flex flex-col">
        <FirstSection lang={lang} setLang={setLang} />
        <div
          style={{
            background: theme.currentTheme.background,
            color: theme.currentTheme.text,
          }}
        >
          {/* <SecondSection /> */}
          {/* <div
            className={`w-full h-1 mt-32`}
            style={{
              background: theme.currentTheme.border,
            }}
          /> */}
          {/* <ThirdSection /> */}
          {/* <div
            className={`w-full h-1`}
            style={{
              background: theme.currentTheme.border,
            }}
          /> */}
          {/* <FourthSection /> */}
          {/* <div
            className={`w-full h-1 mt-32`}
            style={{
              background: theme.currentTheme.border,
            }}
          /> */}
          {/* <FifthSection /> */}
          {/* <div
            className={`w-full h-1 mt-32`}
            style={{
              background: theme.currentTheme.border,
            }}
          /> */}
          {/* <Sixthsection /> */}
        </div>
      </div>
    </>
  );
};

export default HeroComponent;
