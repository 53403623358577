import { useSnackbar } from "notistack";

const useSnackbarAlert = () => {
  const { enqueueSnackbar } = useSnackbar();

  const setSnackbar = ({ message, variant, anchorOrigin }) => {
    enqueueSnackbar(message, {
      variant,
      autoHideDuration: 3000,
      anchorOrigin: anchorOrigin,
    });
  };
  return {
    setSnackbar,
  };
};

export default useSnackbarAlert;
