import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useFormik } from "formik";
import Alert from "@material-ui/lab/Alert";
import useAuth from "../../hooks/useAuth";
import { LoginSchema } from "../../helper/validationSchemas";

// import { LoginSchema } from "../../helper/validationSchemas.js";

// import AuthLayout from "components/AuthLayout/";
// import AuthLayout from "../../components/AuthLayout.jsx";
// import { Input } from "components/common/TextInput";
// import { Button } from "components/common/Button";
// import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
// import IconButton from "@material-ui/core/IconButton";
import { useTranslation } from "react-i18next";
// import ChangeLanguage from "components/common/ChangeLanguage/ChangeLanguage";

// import { validationRed } from "../../Colors/CommonColors.js";
import { validationRed } from "../../Colors/CommonColors";
// import "../../fonts/font.css";

import backgroundImage from "../../assets/images/background-image.png";
import { useLocation, useNavigate } from "react-router-dom";

// import LandingNavbar from "../../components/LandingNavbar.jsx";
import platform from "platform";
// import i18n from "../../i18n.js";
// import {
//   getCurrentLanguage,
//   setCurrentLanguage,
// } from "../../helpers/localStorage";

import {
  getCurrentLanguage,
  setCurrentLanguage,
} from "../../helper/localstorage.js";

const Login = () => {
  const { t } = useTranslation();
  const { loading, login, error } = useAuth();

  const location = useLocation();
  const navigate = useNavigate();

  // useEffect(() => {
  //   if (location.pathname === "/login/ka" || location.pathname === "/ka") {
  //     // i18n.changeLanguage("ka");
  //     // setCurrentLanguage("ka");
  //   } else if (
  //     location.pathname === "/login/en" ||
  //     location.pathname === "/en"
  //   ) {
  //     // i18n.changeLanguage("en");
  //     // setCurrentLanguage("en");
  //   }
  // }, []);

  const initialValues = {
    email: "",
    password: "",
    remember: false,
  };

  const [showPassword, setChowPassword] = useState(false);
  const { handleSubmit, handleChange, setFieldValue, values, errors, touched } =
    useFormik({
      initialValues,
      validationSchema: LoginSchema,

      // eslint-disable-next-line no-shadow
      async onSubmit(values) {
        const data = { ...values };
        delete data.remember;
        login(data).then((res) => {
          if (res === "success") {
            navigate("/purchase");
          }
        });
      },
    });

  const onSignInClick = async (e) => {
    e.preventDefault();
    handleSubmit();
  };

  const currentLng = getCurrentLanguage() || "ka";

  const InputStyles = {
    fontSize: currentLng === "en" ? "16px" : "14px",
    marginBottom: "20px",
    borderRadius: "12px",
    height: "60px",
    autofill: "red",
  };

  const [passwordHidden, setPasswordHidden] = useState(true);

  const handleRedirect = () => {
    const appUri = "com.newlifeapp://";
    let fallbackUrl = "";

    const { family: deviceType = "" } = platform.os ?? {};

    const mobileTypes = ["iOS", "Android"];
    const isMobile = mobileTypes.includes(deviceType);

    if (deviceType === "iOS") {
      fallbackUrl = "https://appstore.com";
    }

    if (deviceType === "Android") {
      fallbackUrl = "https://playstore.com";
    }

    if (isMobile) {
      window.location.replace(appUri);

      // fallback
      window.setTimeout(() => {
        window.location.replace(fallbackUrl);
      }, 2000);
    }
  };

  return (
    <>
      {/* <LandingNavbar /> */}
      <div className="w-full h-full flex flex-col items-center justify-center absolute top-0 left-0 z-1 landing">
        <div className="w-full flex flex-col h-full align-center items-center text-center justify-center text-white">
          <StyledContainer className="bg-black/70 flex flex-col items-center justify-center rounded-xl w-full">
            <div className="h-full w-full m-30">
              <div
                className={`text-3xl w-full mt-5 ${
                  currentLng === "ka" && "georgianFonts capitalLetters"
                }`}
              >
                {t("login.log_in")}
              </div>
              {error && (
                <ServerErorBox className={`${error ? "show" : ""}`}>
                  <Alert severity="error" style={{ color: validationRed }}>
                    {error}
                  </Alert>
                </ServerErorBox>
              )}
              <form className="flex flex-col w-full h-1/7 mt-10 items-center justify-center">
                <input
                  type="text"
                  style={InputStyles}
                  placeholder={t("form_fields.email")}
                  className="w-full  h-14 px-4 rounded-2xl bg-[#333333] border-[#505050] placeholder-[#ffffff70] loginInput"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                />
                <div className="relative w-full">
                  <input
                    type={passwordHidden ? "password" : "text"}
                    placeholder={t("form_fields.password")}
                    className="w-full h-14 px-4 rounded-2xl bg-[#333333] border-[#505050] placeholder-[#ffffff70] mt-5 relative "
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                    onKeyDown={(e) => {
                      // Prevent form submission on Enter key
                      if (e.key === "Enter") {
                        e.preventDefault();
                        handleSubmit();
                      }
                    }}
                  />
                  {passwordHidden ? (
                    <button
                      className="absolute top-0 right-0 mt-9 mr-5"
                      onClick={(e) => {
                        e.preventDefault();
                        setPasswordHidden(!passwordHidden);
                      }}
                      style={{
                        width: "26px",
                        height: "22px",
                        cursor: "pointer",
                      }}
                    >
                      <VisibilityOff />
                    </button>
                  ) : (
                    <button
                      className="absolute top-0 right-0 mt-9 mr-5"
                      onClick={(e) => {
                        e.preventDefault();
                        setPasswordHidden(!passwordHidden);
                      }}
                      style={{
                        width: "26px",
                        height: "22px",
                        cursor: "pointer",
                      }}
                    >
                      <Visibility />
                    </button>
                  )}
                </div>
                <span className="w-full mt-5">
                  <div className="flex flex-nowrap rounded-2xl text-white cursor-pointer h-14 bg-[#007DFF]">
                    <button
                      style={{ paddingTop: 5 }}
                      className={`w-full h-full flex items-center justify-center ${
                        currentLng === "ka" && "georgianFonts capitalLetters"
                      }`}
                      onClick={onSignInClick}
                    >
                      {t("login.log_in")}
                    </button>
                  </div>
                </span>
                <div
                  style={{ fontSize: "16px", margin: "29px 0px 18px 0px" }}
                  className="w-full flex text-sm font-light mt-5"
                >
                  <Link
                    to="/forgot-password"
                    className="underline cursor-pointer"
                  >
                    {t("login.login_forgot_password")}
                  </Link>
                </div>
                <div
                  style={{ fontSize: "16px" }}
                  className="w-full flex mt-2 text-sm font-light"
                >
                  {t("login.dont_have_account")}
                  <span className="text-[#007DFF] ml-2 underline cursor-pointer">
                    <Link to={"/signup"} className="underline cursor-pointer">
                      {t("login.sign_up")}
                    </Link>
                  </span>
                </div>
              </form>
            </div>
          </StyledContainer>
        </div>
      </div>
    </>
  );
};

export default Login;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 650px;
  height: 476px;
`;
export const LoginPopupTitle = styled.div`
  font-size: ${({ currentLng }) => (currentLng === "en" ? "32px" : "28px")};

  text-align: center;
`;

const Content = styled.div`
  position: relative;
  margin: 20px 0 20px 0;
  width: 352px;
`;

const ServerErorBox = styled.div`
  width: 100%;
  opacity: 0;
  transition: opacity 0.2s ease;
  z-index: 100;
  background: pink !important;
  &.show {
    opacity: 1;
  }

  .MuiAlert-root {
    padding: 0 12px;
  }
`;

const LinksWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
`;

const TermsWrapper = styled.div`
  font-size: ${({ currentLng }) => (currentLng === "en" ? "16px" : "12px")};

  text-align: center;
  align-self: center;
`;

const StyledLink = styled.div`
  transition: 0.3s ease-out;
  text-align: left;
  font-size: ${({ currentLng }) => (currentLng === "en" ? "16px" : "12px")};

  text-decoration: underline;
  &:hover {
    color: #72afd2;
  }
`;

const LoginButton = styled.div`
  width: 100%;
  display: flex;
  .btn-wrapper {
    width: 100%;
  }
`;

const StyledContainer = styled.div`
  padding: 50px 92px 62px 92px;
  max-width: 536px;

  @media (max-width: 500px) {
    padding: 50px 45px;
  }
`;
