import { Link } from "react-router-dom";
import { useState } from "react";
import Footer from "../../components/footer/footer";
import Navbar from "../../components/navbar-components/navbar";
import backgroundImage from "../../assets/images/background-image.png";
import { useTranslation } from "react-i18next";
import { getCurrentLanguage } from "../../helper/localstorage";

const Business = () => {
  const { t } = useTranslation();
  const [lang, setLang] = useState(localStorage.getItem("i18nextLng"));

  return (
    <>
      <Navbar lang={lang} setLang={setLang} className />
      <div className="bg-black px-0 sm:px-20 mt-24 ">
        <img
          src={backgroundImage}
          alt="background"
          className="w-full absolute top-0 left-0 h-[60vh] h-full object-cover z-[0]"
        />
        <div className="w-full absolute top-0 left-0 h-[60vh] z-[1] bg-gradient-to-b from-transparent to-black"></div>
        <div
          className={`w-full flex 
            justify-center text-3xl font-bold mt-3 mb-20 xl:mb-40 text-white relative z-[2]  `}
        >
          {t("header_nav.business")}
        </div>
        <div className="w-full  flex flex-col items-center justify-center relative z-[2]">
          <div className="flex flex-col  align-center items-center text-center justify-center text-white w-[95%]">
            <div className="bg-black w-full rounded-xl">
              <div className="sm:w-[80vw] w-[100%] bg-black flex flex-col items-center justify-center rounded-xl p-5 sm:p-10 text-left">
                <p className="my-2">{t("business.first_paragraph")}</p>
                <p className="my-2">{t("business.second_paragraph")}</p>
                <p className="my-2">{t("business.third_paragraph")}</p>
                <p className="my-2"> {t("business.fourth_paragraph")}</p>
                <p className="my-2"> {t("business.fifth_paragraph")}</p>
                <p className="my-2">{t("business.sixth_paragraph")}</p>
                <p className="my-2">{t("business.seventh_paragraph")}</p>
                {t("business.eighth_paragraph")}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Business;
