import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import emailIcon from "../../assets/images/email-icon.png";
import { getCurrentLanguage } from "../../helper/localstorage";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <div className="w-full h-full flex xl:flex-row md:flex-col flex-col justify-between bg-[#131313] px-5 ">
      <div className="container mx-auto flex xl:flex-row flex-col-reverse my-4 text-white ">
        <div className="xl:w-1/2 w-full flex flex-col xl:items-start items-center xl:my-0 my-2 justify-center">
          <div
            className={` text-[0.8em] ${
              getCurrentLanguage() === "en"
              // ? 'font-["Tahoma"]'
              // : 'font-["HelveticLT"]'
            }`}
          >
            © 2021 WENROLL. {t("footer.allRightsReserved")}.
          </div>
          {/* <div className='mt-2 xl:text-[1em] text-[0.8em]'>Powered by {'<elliot/>'}</div> */}
        </div>
        <div className="w-full flex xl:flex-row flex-col xl:items-start items-center xl:justify-end justify-center">
          {/* <Link to={"/business"}>
            <div
              className={`cursor-pointer xl:text-[1em] text-[0.8em] xl:my-0 my-1 ${
                getCurrentLanguage() === "en"
              }`}
            >
              {t("header_nav.business")}
            </div>
          </Link> */}
          {/* <span className="xl:mx-10 mx-2 hidden xl:block">|</span> */}
          <Link to={"/privacy"}>
            <div
              className={`cursor-pointer  text-[0.8em] xl:my-0 my-1 ${
                getCurrentLanguage() === "en"
                // ? 'font-["Tahoma"]'
                // : 'font-["HelveticLT"]'
              }`}
            >
              {t("footer.privacyPolicy")}
            </div>
          </Link>
          <span className="mx-2">|</span>
          <Link to={"/terms"}>
            <div
              className={`cursor-pointer  text-[0.8em] xl:my-0 my-1 ${
                getCurrentLanguage() === "en"
                // ? 'font-["Tahoma"]'
                // : 'font-["HelveticLT"]'
              }`}
            >
              {t("footer.termsOfService")}
            </div>
          </Link>
          <div
            className={`xl:ml-20 xl:my-0 my-5 cursor-pointer flex flex-row ${
              getCurrentLanguage() === "en"
              // ? 'font-["Tahoma"]'
              // : 'font-["HelveticLT"]'
            }`}
          >
            <img
              src={emailIcon}
              alt="email"
              className="md:mr-2 mr-1  text-[0.8em]"
            />
            support@wenroll.com
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
