import Footer from "../../components/footer/footer";
import HeroComponent from "../../components/home-page-components/hero-components/hero-component";
import Navbar from "../../components/navbar-components/navbar";
import { useState } from "react";

const HomePage = () => {
  const [lang, setLang] = useState("ka");

  return (
    <div className="flex flex-col overflow-hidden">
      <Navbar lang={lang} setLang={setLang} />
      <HeroComponent lang={lang} setLang={setLang} />
      <Footer />
    </div>
  );
};

export default HomePage;
