import { Link } from "react-router-dom";
import Footer from "../../components/footer/footer";
import Navbar from "../../components/navbar-components/navbar";
import backgroundImage from "../../assets/images/background-image.png";
import { useTranslation } from "react-i18next";

const Privacy = () => {
  const { t } = useTranslation();
  const sectionThree = t("privacy_policy.3_list", { returnObjects: true });
  const sectionSix = t("privacy_policy.6_list", { returnObjects: true });
  const sectionThreeEng = t("privacy_policy_eng.3_list", {
    returnObjects: true,
  });
  const sectionSixEng = t("privacy_policy_eng.6_list", { returnObjects: true });
  console.log(sectionThree, "sectionThree");
  return (
    <>
      <Navbar className />
      <div
        className="bg-black px-0 md:px-20 mt-24"
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundPosition: "bottom",
        }}
      >
        <img
          src={backgroundImage}
          alt="background"
          className="w-full absolute top-0 left-0 h-[60vh] h-full object-cover z-[0]"
        />
        <div className="w-full absolute top-0 left-0 h-[60vh] z-[1] bg-gradient-to-b from-transparent to-black"></div>
        <div
          className="w-full flex 
            justify-center text-2xl md:text-3xl font-bold my-5 text-white relative z-[2] text-center"
        >
          {t("footer.privacyPolicy")}
        </div>
        <div className="w-full h-full flex flex-col items-center justify-center relative z-[2]">
          <div className="flex w-[90%] md:w-[unset] flex-col h-full align-center  items-center text-center justify-center text-white">
            <div className="bg-black w-full rounded-xl">
              <div className=" w-[100%] md:w-[80vw] bg-black flex flex-col items-center justify-center rounded-xl p-3 md:p-10 text-left">
                <p className="text-left">{t("privacy_policy.1")}</p>
                <br />

                <p className="text-left w-full">{t("privacy_policy.2")}</p>
                <br />

                <p className="text-left w-full">{t("privacy_policy.3")}</p>
                <ul className="text-left w-full mt-5 list-disc  pl-8">
                  {sectionThree.map((item) => (
                    <li className="mt-1">{item}</li>
                  ))}
                </ul>
                <br />
                <p className="w-full text-left ">{t("privacy_policy.4")}</p>
                <br />
                <p>{t("privacy_policy.5")}</p>
                <br />
                <h2 className="w-full text-left font-bold">
                  {t("privacy_policy.6")}
                </h2>
                <ul className="text-left w-full mt-5 list-disc  pl-8">
                  {sectionSix.map((item) => (
                    <li className="mt-1">{item}</li>
                  ))}
                </ul>
                <br />
                <p>{t("privacy_policy.7")}</p>
                <br />
                <p className="w-full text-left">{t("privacy_policy.8")}</p>
                <br />

                <p className="w-full text-left">{t("privacy_policy.9")}</p>
                <br />

                <p className="w-full text-left">{t("privacy_policy.10")}</p>
                <br />

                <p className="w-full text-left">{t("privacy_policy.11")}</p>
                <br />

                <p className="w-full text-left">{t("privacy_policy.12")}</p>
                <br />

                <p className="w-full text-left">{t("privacy_policy.13")}</p>
                <br />

                <p className="w-full text-left">{t("privacy_policy.14")}</p>
                <br />

                <p className="w-full text-left">{t("privacy_policy.15")}</p>
                <br />

                <p className="w-full text-left">{t("privacy_policy.16")}</p>
                <br />

                <p className="w-full text-left">{t("privacy_policy.17")}</p>
                <br />

                <p className="w-full text-left">{t("privacy_policy.18")}</p>
                <br />

                <p className="w-full text-left">{t("privacy_policy.19")}</p>
                <br />

                <p className="w-full text-left">{t("privacy_policy.20")}</p>

                <br />
              </div>
            </div>
          </div>
        </div>
        <div
          className="w-full flex 
            justify-center text-2xl md:text-3xl font-bold my-5 text-white relative z-[2] text-center"
        >
          {t("footer.privacyPolicyEng")}
        </div>
        <div className="w-full h-full flex flex-col items-center justify-center relative z-[2]">
          <div className="flex w-[90%] md:w-[unset] flex-col h-full align-center  items-center text-center justify-center text-white">
            <div className="bg-black w-full rounded-xl">
              <div className=" w-[100%] md:w-[80vw] bg-black flex flex-col items-center justify-center rounded-xl p-3 md:p-10 text-left">
                <p className="text-left w-full ">{t("privacy_policy_eng.1")}</p>
                <br />
                <p className="text-left w-full">{t("privacy_policy_eng.2")}</p>
                <br />
                <p className="text-left w-full">{t("privacy_policy_eng.3")}</p>
                <ul className="text-left w-full mt-5 list-disc  pl-8">
                  {sectionThreeEng.map((item) => (
                    <li className="mt-1">{item}</li>
                  ))}
                </ul>
                <br />
                <p className="w-full text-left ">{t("privacy_policy_eng.4")}</p>
                <br />
                <p>{t("privacy_policy_eng.5")}</p>
                <br />
                <h2 className="w-full text-left font-bold">
                  {t("privacy_policy_eng.6")}
                </h2>
                <ul className="text-left w-full mt-5 list-disc  pl-8">
                  {sectionSixEng.map((item) => (
                    <li className="mt-1">{item}</li>
                  ))}
                </ul>
                <br />
                <p className="w-full text-left">{t("privacy_policy_eng.7")}</p>
                <br />
                <p className="w-full text-left">{t("privacy_policy_eng.8")}</p>
                <br />

                <p className="w-full text-left">{t("privacy_policy_eng.9")}</p>
                <br />

                <p className="w-full text-left">{t("privacy_policy_eng.10")}</p>
                <br />

                <p className="w-full text-left">{t("privacy_policy_eng.11")}</p>
                <br />
                <br />
                <p className="w-full text-left">{t("privacy_policy_eng.12")}</p>
                <br />

                <p className="w-full text-left">{t("privacy_policy_eng.13")}</p>
                <br />

                <p className="w-full text-left">{t("privacy_policy_eng.14")}</p>
                <br />

                <p className="w-full text-left">{t("privacy_policy_eng.15")}</p>
                <br />

                <p className="w-full text-left">{t("privacy_policy_eng.16")}</p>
                <br />

                <p className="w-full text-left">{t("privacy_policy_eng.17")}</p>
                <br />

                <p className="w-full text-left">{t("privacy_policy_eng.18")}</p>
                <br />

                <p className="w-full text-left">{t("privacy_policy_eng.19")}</p>
                <br />

                <p className="w-full text-left">{t("privacy_policy_eng.20")}</p>
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Privacy;
