import { Link } from "react-router-dom";
import logoEnglish from "../../assets/geoLogo.png";
import symbol from "../../assets/simbol.svg";
import styled from "styled-components";
import LightSwitch from "../light-switch/light-switch";
import NavButton from "./nav-button";
import i18n from "../../i18n";
import {
  getCurrentLanguage,
  setCurrentLanguage,
} from "../../helper/localstorage";
import LangSelect from "../lang-select";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

const Navbar = ({ lang, setLang }) => {
  const { t } = useTranslation();
  const [loginRoute, setLoginRoute] = useState("");
  setCurrentLanguage("ka");

  useEffect(() => {
    if (window.location.hostname === "stage.wenroll.com") {
      setLoginRoute(
        `https://stgadmin.wenroll.com/login/${getCurrentLanguage()}`,
      );
    } else if (window.location.hostname === "www.wenroll.com") {
      setLoginRoute(`https://admin.wenroll.com/login/${getCurrentLanguage()}`);
    } else if (window.location.hostname === "test.wenroll.com") {
      setLoginRoute(
        `https://testadmin.wenroll.com/login/${getCurrentLanguage()}`,
      );
    } else if (window.location.hostname === "localhost") {
      setLoginRoute(`http://localhost:3000/login/${getCurrentLanguage()}`);
    } else {
      setLoginRoute("/");
    }
  }, [lang]);

  const languages = [
    { title: "Eng", code: "en" },
    { title: "Geo", code: "ka" },
  ];

  const location = window.location.pathname;

  const hideElement =
    location === "/login" || location === "/signup" || location === "/reset"
      ? "xl:hidden"
      : "xl:block";

  return (
    <div
      className="fixed top-0 h-32 flex flex-row w-full align-center justify-between z-10 pt-6 px-7 opacity-80 bg-origin-padding bg-no-repeat bg-gradient-to-b from-black"
      style={{ alignItems: "center" }}
    >
      <div className="flex flex-row cursor-pointer items-start">
        <Link to="/" className="w-full h-full">
          <img
            src={logoEnglish}
            alt="logo"
            className="hidden sm:inline sm:h-[50px] mr-2"
          />
          <img src={symbol} alt="logo" className="h-[50px] sm:hidden mr-2" />
        </Link>
      </div>
      <Link to="/login">
        <NavButton
          text={"გადახდა"}
          backgroundColor="bg-[#007DFF] hover:bg-[#008DFF]"
        />
      </Link>
      {/* <div className="flex flex-row items-start justify-center">
        <LightSwitch />
        <span className={`hidden ${hideElement}`}>
          <Link to={"/business"}>
            <NavButton
              // englishFont="HelveticMd"
              border={true}
              text={t("header_nav.business")}
            />
          </Link>
        </span>
        <LangSelect
          languages={languages}
          defaultValue={languages.find(
            (lang) => lang.code === getCurrentLanguage(),
          )}
          onChange={(lang) => {
            i18n.changeLanguage(lang.code);

            setLang(lang.code);

            setCurrentLanguage(lang.code);
          }}
        />
        <span className={`hidden ${hideElement}`}>
          <a href={loginRoute}>
            <NavButton
              englishFont="Tahoma"
              text={t("header_nav.login")}
              backgroundColor="bg-[#007DFF] hover:bg-[#008DFF]"
            />
          </a>
        </span>
      </div> */}
    </div>
  );
};

export default Navbar;
const Button = styled.button.attrs((props) => ({
  disabled: props.disabled,
}))`
  border-radius: 48px;
  background: ${(props) => (props.disabled ? "#999999" : "#056ec4")};
  width: 132px;
  margin-top: 23px;
  height: 28px;
  color: ${(props) => (props.disabled ? "#666666" : "#f0f0f0")};
  margin-bottom: 15px;
  &:hover {
    background: ${(props) => (props.disabled ? "#999999" : "#ebf1ff")};
    color: ${(props) => (props.disabled ? "#666666" : "#056ec4")};
  }
`;
