import React, { useEffect, useState, useRef } from "react";
import { useLazyQuery, useSubscription } from "@apollo/react-hooks";
import { Link, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import AppBar from "@material-ui/core/AppBar";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import { useTranslation } from "react-i18next";

import ProfileDropdown from "./ProfileDropdown";
import { useUserValue } from "../context/UserContext";
// import { NotificationBadge, NotificationMenu } from "pages/notifications";

import useOutsideClick from "../helper/useOutsideClick";
import Popover from "@material-ui/core/Popover";
import i18n from "i18next";
import logo from "../assets/logo.png";

// import { mainColor, mainBlack } from "./common/Colors/CommonColors";
// import ChangeLanguage from "./common/ChangeLanguage/ChangeLanguage";
import { TFunction } from "i18next";
// import { vPermission } from "utils/permission";
import { Switch, useTheme, useMediaQuery } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "#1F2431",
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.easeOut,
      duration: ".2s",
    }),
  },
  appBarShift: {
    width: `calc(100% - 370px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.easeOut,
      duration: ".2s",
    }),
  },
  appBarClosed: {
    width: `calc(100% - 187px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.easeOut,
      duration: ".2s",
    }),
  },
  menuButton: {
    marginRight: 36,
    color: "mainColor",
  },
  hide: {
    display: "none",
  },
  notification: {
    borderRadius: "16px",
    overflow: "hidden",
    boxShadow: "0px 30px 30px #00000026",
  },
}));

export const pageNames = (t) => ({
  "/": "",
  "/Companies": t("companies_layout.title"),
  "/users/super-admins": t("general.users"),
  "/users/admins": t("general.users"),
  "/users/coaches": t("general.users"),
  "/users/students": t("general.users"),
  "/create-company": t("companies_layout.create_company"),
  "/courses": t("courses_layout.title"),
  "/modules": t("modules_layout.title"),
  "/create-course": t("course_drawer_layout.add_course"),
});

const Header = ({ currentUser, open, setFilterQuery }) => {
  const history = useNavigate();
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const [state] = useUserValue();
  const ref = useRef();
  const [notificationMenu, setNotificationMenu] = useState(false);
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const pageName = React.useMemo(() => pageNames(t)[pathname], [pathname, t]);
  // const themeToggle = useToggleTheme();

  const [themeMode, setThemeMode] = useState(false);
  const [first, setFirst] = useState(true);

  const currentLanguage = React.useMemo(() => i18n.language, [i18n.language]);

  useEffect(() => {
    document.body.className =
      currentLanguage === "en" ? "englishFonts" : "georgianFonts";
  }, [currentLanguage]);

  // useEffect(() => {
  //   // if (!first) themeToggle(themeMode);
  //   else {
  //     const mode = localStorage.getItem("dark");
  //     if (mode) {
  //       setThemeMode(JSON.parse(mode));
  //     }

  //     setFirst(false);
  //   }
  // }, [themeMode]);

  const theme = useTheme();

  useOutsideClick(ref, () => {
    // if (notificationMenu) setNotificationMenu(false);
  });
  // useEffect(() => {
  //   setNotificationMenu(false)

  // }, [pathname])

  const handleClick = (event) => {
    // setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // const { data: notificationListener } = useSubscription(NOTIFICATION_LISTENER);

  // const [fetchNotificationCount, { loading, error, data }] = useLazyQuery(
  //   GET_NOTIFICATION_COUNT,
  // );
  // console.log(notificationListener, "notificationListener");
  // useEffect(() => {
  //   fetchNotificationCount();
  // }, [notificationListener]);

  useEffect(() => {
    setAnchorEl(null);
  }, [pathname]);

  // const numberOfNewNotifications =
  //   (data && data.getNotificationCount.numberOfNotifications) || 0;

  const menuOpen = Boolean(anchorEl);
  const id = notificationMenu ? "simple-popover" : undefined;
  const isStudent =
    state.currentUser.role === "STUDENT" || state.currentUser.role === "COACH";

  // const isCompanySuperAdmin =
  //   state?.currentUser?.role === "SUPER_ADMIN" &&
  //   !!state?.currentUser?.companyId?.id;

  const isCompanyAdmin =
    state?.currentUser?.role === "ADMIN" && !!state?.currentUser?.companyId?.id;

  const isSmallScreen = useMediaQuery(theme.breakpoints.up("sm"));
  const ThemeSwitch = styled(Switch)(({ theme }) => ({
    width: isSmallScreen ? 61 : 41,
    height: isSmallScreen ? 37 : 25,
    padding: isSmallScreen ? 7 : 13,
    border: `1px solid ${theme.palette.text.primary}`,
    borderRadius: "25px",
    "& .MuiSwitch-switchBase": {
      margin: 1,
      padding: 0,
      transform: "translateX(3px) translateY(3px)",
      "&.Mui-checked": {
        color: "#fff",
        transform: "translateX(27px) translateY(3px)",
        "& .MuiSwitch-thumb:before": {
          backgroundImage: `url(../svg/themedark.svg)`,
        },
        "& + .MuiSwitch-track": {
          opacity: 0,
        },
      },
    },
    "& .MuiSwitch-thumb": {
      backgroundColor: "transparent",
      border: `1px solid ${theme.palette.text.primary}`,
      borderRadius: "100%",
      width: isSmallScreen ? 27 : 18,
      height: isSmallScreen ? 27 : 18,
      "&:before": {
        content: "''",
        position: "absolute",
        width: "100%",
        height: "100%",
        left: 0,
        top: 0,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundImage: `url(../svg/themelight.svg)`,
      },
    },
    "& .MuiSwitch-track": {
      opacity: 0,
    },
  }));

  return (
    <StyledHeader theme={theme}>
      <AppBar
        position="fixed"
        style={isStudent ? { width: "100%" } : {}}
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
          [classes.appBarClosed]: !open,
        })}
      >
        <Toolbar
        // className={`${
        //   vPermission.includes(state?.currentUser?.role) && "container-md"
        // }`}
        >
          <Wrapper>
            <img
              src={logo}
              alt="logo"
              className="w-[150px] h-[30px] sm:w-[245px] sm:h-[50px] mr-2 "
            />
            {
              //   vPermission.includes(state?.currentUser?.role) ? (
              //   <Icon
              //     filename={'wenroll_logo_text'}
              //     onClick={() => { window.location.replace("/") }}
              //     cursor={'pointer'}
              //     height={30}
              //     width={isSmallScreen ? 148 : 120}
              //   />
              //   <img
              //     src={logo}
              //     alt="logo"
              //     className="w-0 h-0 sm:w-[245px] sm:h-[50px] mr-2 "
              //   />
              // ) : (
              <>
                {state?.currentUser?.role != "STUDENT" && (
                  <PageTitle theme={theme}></PageTitle>
                )}
              </>
              // )
            }

            <ItemWrapper>
              {/* {state?.currentUser?.role != "STUDENT" && (
                <ThemeSwitch
                  defaultChecked={themeMode}
                  checked={themeMode}
                  theme={theme}
                  onClick={() => {
                    setThemeMode(!themeMode);
                  }}
                />
              )} */}
              {/* 
              {state?.currentUser?.role != "STUDENT" && (
                <ChangeLanguage themeMode={themeMode} />
              )} */}
              {/* {state?.currentUser?.role != "STUDENT" && (
                <NotificationContainer>
                  <NotificationBadge
                    open={handleClick}
                    numberOfNewNotifications={numberOfNewNotifications}
                  />
                  <Popover
                    id={id}
                    open={menuOpen}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    style={{
                      marginTop: "30px",
                      marginLeft: "14px",
                    }}
                    classes={{
                      paper: classes.notification,
                    }}
                  >
                    <NotificationMenu
                      notificationBadgeRefetch={fetchNotificationCount}
                      user={state?.currentUser}
                    />
                  </Popover>
                </NotificationContainer>
              )} */}
              {/* test */}
              {currentUser && <ProfileDropdown user={currentUser} t={t} />}
            </ItemWrapper>
          </Wrapper>
        </Toolbar>
      </AppBar>
    </StyledHeader>
  );
};

export default Header;

const Logo = styled.figure`
  text-align: center;
  color: "#fff";
  display: flex;
`;

const StyledHeader = styled.header`
  .MuiPaper-elevation4 {
    border-bottom: 1px solid rgb(188, 209, 242);
    box-shadow: none !important;
    background: rgb(188, 209, 242);
    width: 100%;
    padding: 20px;
  }
  &::before {
    content: "";
    position: fixed;
    top: 0;
    width: 100%;
    height: 462px;
    background: transparent
      linear-gradient(180deg, #317bf45c 0%, #317bf400 100%) 0% 0% no-repeat
      padding-box;
  }
  .MuiToolbar-root {
    padding: 27px 24px;
    @media (max-width: 450px) {
      padding: 27px 0;
    }
  }
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const PageTitle = styled.div`
  font-size: 32px;
  letter-spacing: 0px;
  color: ${({ theme }) => theme.palette.text.primary};
  text-transform: uppercase;
`;

const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  @media (max-width: 600px) {
    gap: 8px;
  }
`;
const NotificationContainer = styled.div`
  margin-right: 14px;
  position: relative;
  .MuiPopover-paper {
    overflow-y: unset !important;
    overflow-x: unset !important;
  }
  @media (max-width: 600px) {
    margin-right: unset;
  }
`;
