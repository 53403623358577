import React, { FormEvent } from "react";
import styled from "styled-components";
import TextField from "@material-ui/core/TextField";
import { useLocation } from "react-router-dom";

import ErrorOutlineRoundedIcon from "@material-ui/icons/ErrorOutlineRounded";
import CheckCircleOutlineRoundedIcon from "@material-ui/icons/CheckCircleOutlineRounded";
// import "./TextInput.scss";
import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { validationRed } from "../../Colors/CommonColors";
import { useTheme } from "@material-ui/core";

const TextInput = ({
  label,
  size,
  textColor,
  icon,
  name,
  type,
  value,
  error,
  errorMessage,
  touched,
  fullWidth,
  placeholder,
  onChange,
  onBlur,
  onFocus,
  multiline,
  rows,
  validate = true,
  disabled = false,
  readOnly = false,
  endAdornment,
  startAdornment,
  component,
  inputStyle,
  min,
  max,
}) => {
  const [showPassword] = React.useState("password");

  const useStyles = makeStyles((theme) =>
    createStyles({
      root: {
        "& .Mui-error": {
          color: validationRed,
        },
        "& .MuiFormLabel-root": {
          ...inputStyle,
        },
      },
    }),
  );
  const classes = useStyles();
  const theme = useTheme();
  const { pathname } = useLocation();

  const inputStyles = { WebkitBoxShadow: "0 0 0 1000px #ffffff inset" };

  return (
    // @ts-ignore
    <Field className={fullWidth ? "full" : ""}>
      {/* @ts-ignore */}
      <STextField
        theme={theme}
        error={touched && error ? true : false}
        helperText={touched && error ? errorMessage : ""}
        FormHelperTextProps={{
          color: "#D2364B",
        }}
        id="standard-basic"
        label={label}
        InputLabelProps={{
          style: {
            background: theme.palette.background.default,
            color: theme.palette.text.primary,
          },
        }}
        variant="outlined"
        size={size}
        name={name}
        type={type === "password" ? showPassword : type}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        style={{ width: "100%" }}
        multiline={multiline}
        placeholder={placeholder}
        rows={rows}
        disabled={disabled}
        InputProps={{
          inputProps: {
            min: min,
            max: max,
          },
          readOnly: readOnly,
          startAdornment: startAdornment,
          endAdornment: endAdornment,
          style: {
            ...inputStyle,
            color: textColor || "#000000",
          },
        }}
        inputProps={
          pathname !== "/forgot-password" ? { style: inputStyles } : {}
        }
        autoComplete="off"
        className={classes.root}
      />
      {touched && validate && (
        // @ts-ignore
        <ValidationIcon className={`${error ? "error" : "valid"}`}>
          {error ? (
            // @ts-ignore
            <ErrorOutlineRoundedIcon />
          ) : (
            // @ts-ignore
            <CheckCircleOutlineRoundedIcon />
          )}
        </ValidationIcon>
      )}
      {icon && icon}
      {component ? (
        // @ts-ignore
        <StyledComponentContainer>{component}</StyledComponentContainer>
      ) : null}
    </Field>
  );
};

export { TextInput };

const STextField = styled(TextField)`
  fieldset {
    border-radius: 8px;
    border: 1px solid #080a0a14;
    outline: none;
  }
  div {
    input,
    textarea {
      font-size: 16px;
      font-weight: 500;
    }
  }
  &:hover {
    fieldset {
      border: 1px solid #5290f6 !important;
    }
  }
  .Mui-focused {
    fieldset {
      border: 1px solid #5290f6 !important;
    }
  }
`;

const Field = styled.div`
  position: relative;
  label {
    z-index: 1 !important;
    background: inherit !important;
  }
  &.full {
    width: 100%;
  }

  p {
    position: absolute;
    left: -10px;
    bottom: -24px;
  }

  button {
    position: absolute;
    right: 5px;
    top: 2px;
    padding: 5px;
  }
`;

const ValidationIcon = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 6px;
  width: unset !important;

  &.error svg {
    color: "#D2364B";
  }

  &.valid svg {
    color: "#00C78E";
  }
`;

const StyledComponentContainer = styled.div`
  position: absolute;
  top: 5px;
  right: 4px;
  z-index: 2;
  .btn--small {
    overflow: initial !important;
  }
`;
