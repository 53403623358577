export const userInitialState = {
  isAuthenticated: false,
  currentUser: {},
  currentCompany: {},
  selectedCompanyId: null,
  userPermission: {},
  closeDrawer: {
    text: "",
    confirm: false,
  },
  refetch: false,
};

export const userReducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
      return { ...state, isAuthenticated: true };

    case "LOGOUT":
      return { ...state, isAuthenticated: false };

    case "SET_REFETCH":
      return { ...state, refetch: true };

    case "REMOVE_REFETCH":
      return { ...state, refetch: false };

    case "FETCH_CURRENT_USER":
      return { ...state, currentUser: action.payload };

    case "SET_CURRENT_COMPANY":
      return { ...state, currentCompany: action.payload };

    case "CURRENT_USER_PERMISSION":
      return { ...state, userPermission: action.payload };

    case "SET_SELECTED_COMPANY_ID": {
      return { ...state, selectedCompanyId: action.payload };
    }

    case "TOGGLE_CLOSE_DRAWER": {
      return {
        ...state,
        closeDrawer: {
          text: action.payload.text,
          confirm: action.payload.confirm,
        },
      };
    }

    default:
      return state;
  }
};
