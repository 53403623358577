import { useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import { useTranslation } from "react-i18next";

import { useUserValue } from "../context/UserContext";
import { LOGIN } from "../gql/login.query";
import {
  clearToken,
  setRole,
  setToken,
  setUserId,
} from "../helper/localstorage.js";

const useAuth = () => {
  const { t } = useTranslation();
  const [state, dispatch] = useUserValue();
  //@ts-ignore
  const [authenticate] = useMutation(LOGIN);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  // const { updateUserFirstLogin } = useUpdateUserFirstLoginService()

  const login = async (loginValues) => {
    loginValues.deviceType = "WEB";
    // loginValues.deviceId = "deviceId"
    try {
      setLoading(true);
      const { data, errors } = await authenticate({
        variables: { input: loginValues },
      });
    
      if (errors) {
      
        const errorMessage = errors[0].message;
      
        setError(
          errorMessage === "Invalid credentials"
            ? t("login.invalid_credentials")
            : errorMessage === "SUSPENDED"
            ? t("login.user_is_suspanded")
            : errorMessage,
        );
        setTimeout(() => {
          setError("");
        }, 3000);
        return false;
      }
      if (data.auth.user.requestPasswordChange) {
        setError("ექუნთის ვერიფიკაციისთვის გამოიყენეთ აპლიკაცია");
        setTimeout(() => {
          setError("");
        }, 3000);
        return false;
      }

      const { accessToken, user } = data.auth;

      // if(user.isFirstLogin){
      //   if(user.requestPasswordChange){
      //     history.push("/change-password")
      //   }else{
      //     updateUserFirstLogin(user.id)
      //   }
      // }else{
      //   history.push("/")

      // }
      setRole(user.role);
      setUserId(user.id);
      setToken(accessToken);
      dispatch({
        type: "LOGIN",
      });
      return "success";
    } catch (err) {
      console.log("Error from login request", err);
    } finally {
      setLoading(false);
    }
  };

  const logout = () => {
    dispatch({
      type: "LOGOUT",
    });
    clearToken();
    localStorage.clear();
    window.location.href = "/login";
  };

  return {
    loading,
    login,
    logout,
    error,
  };
};

export default useAuth;
