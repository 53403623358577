import { gql } from "graphql-tag";

export const GET_SUBSCRIPTION_PRODUCTS = gql`
  query {
    getSubscriptionProducts {
      data {
        id
        price
        currency
        occuarenceType
        occurrenceNumber
        status
      }
    }
  }
`;

export const GET_CURRENT_USER = gql`
  query {
    currentUser {
      id
      firstName
      lastName
      isRegistered
      email
      courses {
        name
        courseId
      }
      role
      companyId {
        name
        id
        platformService
      }
      avatar
      requestPasswordChange
      settings {
        askPassword
      }
      jobTitle
      biography
      location
      phone
      gender
      birthDate
      createDate
      note
      notes {
        id
        actionId
        title
        description
        moment
        thumbnail
        createDate
        courseId {
          name
          avatar {
            name
            link
            fileType
          }
        }
        lessonInfo {
          videoLink {
            name
            key
            thumbnail
            duration
            id
            title
            links {
              url
              fileType
            }
          }
          _id
          name
          duration
          topicId
          transcript
          description
          watchDuration
          numberOfModuleQuestions
          topicName
          topicId
          progress
          blocked
          moduleName
          moduleId
          courseId
          courseName
          coaches
          courseCover
          isFavorited
        }
      }
      status
      age
      phoneFields {
        code
        dialCode
      }
      isCompanyAdmin
      isSuperAdmin
      group {
        name
        groupId
      }
    }
  }
`;
