import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { BrowserRouter } from "react-router-dom";
import App from "./app";
// import "./i18n";
import { ApolloProvider } from "@apollo/react-hooks";
import { client } from "./apollo";
import { DataProvider } from "./context/DataContext";
import { UserProvider } from "./context/UserContext";
import { dataInitialState, dataReducer } from "./reducers/dataReducers";
import { userInitialState, userReducer } from "./reducers/userReducers";
// test
ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <DataProvider initialState={dataInitialState} reducer={dataReducer}>
        <UserProvider initialState={userInitialState} reducer={userReducer}>
          <ApolloProvider client={client}>
            <App />
          </ApolloProvider>
        </UserProvider>
      </DataProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root"),
);
