import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { getCurrentLanguage } from "./helper/localstorage";
import en from "./locales/en/translation.json";
import ka from "./locales/ka/translation.json";

const currentLanguage = getCurrentLanguage();
let lang = "eng";

if (!!currentLanguage) {
  lang = currentLanguage;
}

export const resources = {
  ka: {
    translations: ka,
  },
  en: {
    translations: en,
  },
};

i18n.use(initReactI18next).init({
  fallbackLng: ["en", "ka"],
  debug: false,
  lng: lang,
  resources,
  defaultNS: "translations",
  interpolation: {
    escapeValue: false,
  },
  react: {
    useSuspense: false,
  },
});

export default i18n;
