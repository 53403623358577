export const setCurrentLanguage = (language) => {
  console.log(language, "language");
  localStorage.setItem("currentLanguage", language);
};
export const getCurrentLanguage = () =>
  localStorage.getItem("currentLanguage") || "ka";

export const setToken = (token) => localStorage.setItem("token", token);
export const setSelectedCompanyId = (selectedCompanyId) =>
  localStorage.setItem("selectedCompanyId", selectedCompanyId);
export const setRole = (role) => localStorage.setItem("role", role);
export const getRole = () => localStorage.getItem("role");
export const getToken = () => localStorage.getItem("token");
export const getCompanyMode = () => localStorage.getItem("selectedCompanyId");
export const setUserId = (userId) => localStorage.setItem("userId", userId);
export const getUserId = () => localStorage.getItem("userId") || "";
export const clearToken = () => localStorage.removeItem("token");
export const setCourseId = (courseId) =>
  localStorage.setItem("courseId", courseId);
export const getCourseId = () => localStorage.getItem("courseId") || "";
export const clearCourseId = () => localStorage.removeItem("courseId");
export const clearCompanyModee = () =>
  localStorage.removeItem("selectedCompanyId");
