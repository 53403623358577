import { createContext } from "react";


const themes = {
  light: {
    background: '#fff',
    text: '#000',
    border: '#F2F2F2',
    innerBorder: '#FFFFFF',
    accordionBG: '#F2F2F2',
    accordionBtnBG: '#F2F2F2',
    accordionArrow: '#191919',
  },
  dark: {
    background: '#000000',
    text: '#fff',
    border: '#131313',
    innerBorder: '#212121',
    accordionBG: '#131313',
    accordionBtnBG: '#191919',
    accordionArrow: '#fff',
  }
}


const currentTheme = themes.light;

const changeTheme = (theme) => {

}


const ThemeContext = createContext({
  themes,
  changeTheme,
  currentTheme,
})

export default ThemeContext;