import React, { useState } from "react";
import { useFormik } from "formik";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
// import { EmailSchema } from "helpers/validationSchemas";

import { EmailSchema } from "../helper/validationSchemas";

// import TextInput from "components/common/TextInput/TextInput";
import { TextInput } from "./common/TextInput";
// import { Button } from "components/common/Button";
import { CustomButton as Button } from "./button/CustomButton";
import LandingNavbar from "./LandingNavbar";

// import useSnackbarAlert from "hooks/useSnackbar";
import useSnackbarAlert from "../hooks/useSnackbar";
// import { snackbarProps } from "interfaces/snackbar";
// import { Api } from "api";
// import { LoginPopupTitle } from "./Login";
import { LoginPopupTitle } from "../pages/login-page/login-page";
// import { mainColor, successGreen } from "components/common/Colors/CommonColors";

import { mainColor, successGreen } from "../Colors/CommonColors";

// import "../../fonts/font.css";
// import { getCurrentLanguage } from "helpers/localStorage";
import { getCurrentLanguage } from "../helper/localstorage";
import styled from "styled-components";
import axios from "axios";

const ForgotPassword = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [success, setSuccess] = useState(false);
  const { setSnackbar } = useSnackbarAlert();

  const { handleSubmit, handleChange, setFieldValue, values, errors, touched } =
    useFormik({
      initialValues: {
        email: "",
      },
      validationSchema: EmailSchema,

      // eslint-disable-next-line no-shadow
      async onSubmit(values) {
        const data = { ...values };

        setLoading(true);

        let { message, variant } = {
          message: "",
          variant: undefined,
        };

        axios
          .post("http://localhost:8001/users/forgotPassword", {
            email: data.email,
          })
          .then((res) => {
            message = `Successful`;
            variant = "success";
            setLoading(false);
            setSnackbar({ message, variant });
            setSuccess(true);
          })
          .catch((err) => {
            message =
              err.response.data.message === "User not Found"
                ? t("general.wrong_email")
                : err.response.data.message;
            variant = "error";
            setLoading(false);
            setSnackbar({ message, variant });
          });
      },
    });

  const currentLng = getCurrentLanguage() || "ka";

  return (
    <>
      {/* <LandingNavbar /> */}
      <div className="w-full h-full flex flex-col items-center justify-center absolute top-0 left-0 z-1 landing">
        <div className="w-full flex flex-col h-full align-center items-center text-center justify-center text-white">
          <div
            className="bg-black/70 flex flex-col items-center justify-center rounded-xl w-full"
            style={{
              padding: "50px 45px",
              maxWidth: "536px",
            }}
          >
            <div className="h-full w-full m-30 flex justify-center align-center">
              <Container className="flex justify-center align-center">
                <ContentWrapper>
                  {!success ? (
                    <>
                      <LoginPopupTitle currentLng={currentLng}>
                        {t("login.password_reset")}
                      </LoginPopupTitle>
                      <Content>
                        <TextInput
                          name="email"
                          type="text"
                          textColor="#ffffff"
                          value={values.email}
                          fullWidth
                          onChange={handleChange}
                          error={!!errors.email}
                          errorMessage={errors.email}
                          touched={touched.email}
                          placeholder={t("general.email")}
                          inputStyle={{
                            fontSize: currentLng === "en" ? "16px" : "14px",

                            borderRadius: "12px",
                            background: "#333333",
                            border: "1px solid #505050",
                            height: "60px",
                          }}
                        />
                      </Content>

                      <Button
                        text={t("login.send")}
                        onClick={handleSubmit}
                        background={mainColor}
                        loading={loading}
                        buttonStyles={{
                          borderRadius: "12px",
                          marginTop: "10px",
                          height: "60px",
                          color: "white",
                          width: "100%",
                          fontSize: currentLng === "en" ? "16px" : "14px",
                        }}
                      />
                      <div className="flex align-center justify-start mt-5">
                        <p style={{ fontSize: "16px" }}>
                          {" "}
                          {t("general.rememberPassword")}
                          <span className="text-[#007DFF] ml-2 underline cursor-pointer">
                            <Link
                              to={"/login"}
                              className="underline cursor-pointer"
                            >
                              {t("login.log_in")}
                            </Link>
                          </span>
                        </p>
                      </div>
                    </>
                  ) : (
                    <>
                      <LoginPopupTitle currentLng={currentLng}>
                        {t("login.password_reset_link_sent")}
                      </LoginPopupTitle>
                      <LoginPopupSubtitle currentLng={currentLng}>
                        {t("login.check_email")}
                      </LoginPopupSubtitle>

                      <Button
                        text={t("login.log_in")}
                        onClick={() => navigate("/login")}
                        background={mainColor}
                        buttonStyles={{
                          borderRadius: "12px",
                          height: "60px",
                          fontSize: currentLng === "en" ? "16px" : "14px",
                        }}
                      />
                    </>
                  )}
                </ContentWrapper>
              </Container>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;

const Container = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 650px;
  height: 290px;
`;
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 352px;
`;

export const LoginPopupSubtitle = styled.div`
  text-align: center;
  font-size: ${({ currentLng }) => (currentLng === "en" ? "16px" : "14px")};

  letter-spacing: 0px;
  color: ${successGreen};
  margin: 30px 0px 40px 0;
`;

const Content = styled.div`
  margin: 20px 0 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;
