import React from "react";
import styled from "styled-components";
import Checkbox from "@material-ui/core/Checkbox";
import { mainColor } from "../Colors/CommonColors";

const Check = ({
  indeterminate,
  checked,
  onChange,
  inputProps,
  name,
  color,
  backgroundColor,
  margin,
  width,
}) => {
  return (
    <CheckboxContainer
      onClick={(e) => e.stopPropagation()}
      width={width}
      margin={margin}
      color={backgroundColor}
    >
      <Checkbox
        indeterminate={indeterminate}
        checked={checked}
        onChange={onChange}
        inputProps={inputProps}
        name={name}
        color={color}
      />
    </CheckboxContainer>
  );
};
export default Check;
const CheckboxContainer = styled.div`
  .MuiButtonBase-root {
    background: #080a0a0a;
    border-radius: 12px;
    width: ${(props) => props.width || "36px"};
    height: 36px;
    margin: ${(props) => props.margin || "0px 10px"};

    &.Mui-checked {
      .MuiIconButton-label {
        &::after {
          content: "";
          position: absolute;
          width: 10px;
          height: 10px;
          background: #317bf4;
          border-radius: 2px;
        }
      }
    }

    .MuiIconButton-label {
      width: 16px;
      height: 16px;
      border: 1px solid #317bf4;
      background: white;
      border-radius: 4px;
      color: transparent;
    }
  }
  .MuiCheckbox-colorPrimary.Mui-checked {
    color: ${({ color }) => color || mainColor} !important;
  }
  .MuiCheckbox-colorSecondary.Mui-checked {
    color: ${({ color }) => color || mainColor} !important;
  }
`;
