/* eslint-disable react/jsx-no-undef */
import { useEffect, useState } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import googlePlay from "../../../assets/images/googlePlay.png";
import appStore from "../../../assets/images/store.png";
// import { Link } from "react-router-dom";
import { getCurrentLanguage } from "../../../helper/localstorage";
import NavButton from "../../navbar-components/nav-button";
import "./landing.css";
const FirstSection = ({ lang, setLang }) => {
  const { t } = useTranslation();
  console.log(lang, "lang");
  const [signupRoute, setSignupRoute] = useState("");
  const sectionOneTitle = t("section_one.title", { returnObjects: true });

  useEffect(() => {
    if (window.location.hostname === "stage.wenroll.com") {
      setSignupRoute(`https://stgadmin.wenroll.com/signup/${lang}`);
    } else if (window.location.hostname === "www.wenroll.com") {
      setSignupRoute(`https://admin.wenroll.com/signup/${lang}`);
    } else if (window.location.hostname === "test.wenroll.com") {
      setSignupRoute(`https://testadmin.wenroll.com/signup/${lang}`);
    } else if (window.location.hostname === "localhost") {
      setSignupRoute(`/signup/${lang}`);
    } else {
      setSignupRoute("/");
    }
  }, [lang]);

  return (
    <div
      className="w-full landing"
      style={{
        height: "100vh",
        position: "relative",
        paddingTop: "50px",
      }}
    >
      {/* <img
        src={backgroundImage}
        alt="background"
        className="w-full h-full absolute top-0 left-0 right-0 bottom-0 object-cover z-[-1]"
      /> */}
      <div
        className={`w-full flex flex-col items-center justify-center h-[100%]`}
      >
        <div className="flex flex-col  h-full align-center text-center justify-center text-white">
          <p
            className={`xl:text-[2em] lg:text-[2em] md:text-[2em] text-[26px]  uppercase font-fira ${
              getCurrentLanguage() === "en"
              // ? 'font-["Gotham"]'
              // : 'font-["FiraGo"]'
            }`}
          >
            {sectionOneTitle?.map((text, index) => (
              <React.Fragment key={index}>
                {text}
                {index === sectionOneTitle.length - 1 ? null : <br />}
              </React.Fragment>
            ))}
          </p>
          <p
            className={`xl:text-xl md:text-lg text-[20px] mt-10 mb-10 uppercase ${
              getCurrentLanguage()
              // ? 'font-["Tahoma"]'
              // : 'font-["HelveticLT"]'
            }`}
          >
            {t("section_one.description")}
          </p>
          {/* <span className="self-center">
            <a href={signupRoute}>
              <NavButton
                // englishFont="Gotham"
                fontSize="22px"
                text={t("header_nav.signup")}
                backgroundColor="bg-[#007DFF] hover:bg-[#008DFF]"
                bold={"bold"}
              />
            </a>
          </span> */}
          <div className="flex justify-around " style={{ padding: "0 112px" }}>
            <a
              href="https://play.google.com/store/apps/details?id=com.wenroll"
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              <img src={googlePlay} alt="googlePlay" />
            </a>

            <a
              href="https://apps.apple.com/us/app/wenroll/id1567062002"
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              <img src={appStore} />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FirstSection;
