import gql from "graphql-tag";

export const LOGOUT = gql`
  query logout {
    logOut {
      userId
      deviceId
      deviceType
    }
  }
`;
