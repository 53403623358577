import Footer from "../../components/footer/footer";
import Navbar from "../../components/navbar-components/navbar";
import backgroundImage from "../../assets/images/background-image.png";

import { useTranslation } from "react-i18next";

const Terms = () => {
  const { t } = useTranslation();
  const terms = t("terms_of_service", { returnObjects: true });
  const content_1 = t("terms_of_service.1_content", { returnObjects: true });
  const content_2 = t("terms_of_service.2_content", { returnObjects: true });
  const content_3 = t("terms_of_service.3_content", { returnObjects: true });
  const content_4 = t("terms_of_service.4_content", { returnObjects: true });
  const content_5 = t("terms_of_service.5_content", { returnObjects: true });
  const content_6 = t("terms_of_service.6_content", { returnObjects: true });
  const content_7 = t("terms_of_service.7_content", { returnObjects: true });
  const termsEng = t("terms_of_service_eng", { returnObjects: true });
  const content_1_eng = t("terms_of_service_eng.1_content", {
    returnObjects: true,
  });
  const content_2_eng = t("terms_of_service_eng.2_content", {
    returnObjects: true,
  });
  const content_3_eng = t("terms_of_service_eng.3_content", {
    returnObjects: true,
  });
  const content_4_eng = t("terms_of_service_eng.4_content", {
    returnObjects: true,
  });
  const content_5_eng = t("terms_of_service_eng.5_content", {
    returnObjects: true,
  });
  const content_6_eng = t("terms_of_service_eng.6_content", {
    returnObjects: true,
  });
  const content_7_eng = t("terms_of_service_eng.7_content", {
    returnObjects: true,
  });

  return (
    <>
      <Navbar className />
      <div
        className="bg-black px-0 sm:px-20 mt-24"
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundPosition: "bottom",
        }}
      >
        <img
          src={backgroundImage}
          alt="background"
          className="w-full absolute top-0 left-0 h-full object-cover z-[0]"
        />
        <div className="w-full absolute top-0 left-0 h-[60vh] z-[1] bg-gradient-to-b from-transparent to-black"></div>
        <div
          className="w-full flex 
            justify-center text-3xl font-bold my-5 text-white relative z-[2]"
        >
          {t("terms_of_service.title")}
        </div>
        <div className="w-full h-full flex flex-col items-center justify-center relative z-[2]">
          <div className="flex flex-col h-full align-center items-center text-center justify-center text-white">
            <div className="bg-black w-full rounded-xl">
              <div className="w-[80vw] bg-black flex flex-col items-center justify-center rounded-xl p-10 text-left">
                <h3 className="w-full text-left font-bold mt-4 mb-2">
                  {t("terms_of_service.1")}
                </h3>
                {content_1.map((item) => (
                  <p className="m-1">{item}</p>
                ))}

                <h3 className="w-full text-left font-bold mt-4 mb-2">
                  {t("terms_of_service.2")}
                </h3>
                {content_2.map((item) => (
                  <p className="m-1">{item}</p>
                ))}

                <h3 className="w-full text-left font-bold mt-4 mb-2">
                  {t("terms_of_service.3")}
                </h3>
                {content_3.map((item) => (
                  <p className="m-1">{item}</p>
                ))}

                <h3 className="w-full text-left font-bold mt-4 mb-2">
                  {t("terms_of_service.4")}
                </h3>
                {content_4.map((item) => (
                  <p className="m-1 w-full text-left">{item}</p>
                ))}

                <h3 className="w-full text-left font-bold mt-4 mb-2">
                  {t("terms_of_service.5")}
                </h3>
                {content_5.map((item) => (
                  <p className="m-1 w-full text-left">{item}</p>
                ))}

                <h3 className="w-full text-left font-bold mt-4 mb-2">
                  {t("terms_of_service.6")}
                </h3>
                {content_6.map((item) => (
                  <p className="m-1 w-full text-left">{item}</p>
                ))}
                <h3 className="w-full text-left font-bold mt-4 mb-2">
                  {t("terms_of_service.7")}
                </h3>
                {content_7.map((item) => (
                  <p className="m-1 w-full text-left">{item}</p>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div
          className="w-full flex 
            justify-center text-3xl font-bold  text-white relative z-[2] mt-6 "
        >
          {t("terms_of_service_eng.title")}
        </div>
        <div className="w-full h-full flex flex-col items-center justify-center relative z-[2]">
          <div className="flex flex-col h-full align-center items-center text-center justify-center text-white">
            <div className="bg-black w-full rounded-xl">
              <div className="w-[80vw] bg-black flex flex-col items-center justify-center rounded-xl p-10 text-left">
                <h3 className="w-full text-left font-bold  ">
                  {t("terms_of_service_eng.1")}
                </h3>
                {content_1_eng.map((item) => (
                  <p className="m-1 w-full text-left">{item}</p>
                ))}
                <h3 className="w-full text-left font-bold mt-4 mb-2">
                  {t("terms_of_service_eng.2")}
                </h3>
                {content_2_eng.map((item) => (
                  <p className="m-1">{item}</p>
                ))}

                <h3 className="w-full text-left font-bold mt-4 mb-2">
                  {t("terms_of_service_eng.3")}
                </h3>
                {content_3_eng.map((item) => (
                  <p className="m-1">{item}</p>
                ))}

                <h3 className="w-full text-left font-bold mt-4 mb-2">
                  {t("terms_of_service_eng.4")}
                </h3>
                {content_4_eng.map((item) => (
                  <p className="m-1 w-full text-left">{item}</p>
                ))}

                <h3 className="w-full text-left font-bold mt-4 mb-2">
                  {t("terms_of_service_eng.5")}
                </h3>
                {content_5_eng.map((item) => (
                  <p className="m-1 w-full text-left">{item}</p>
                ))}

                <h3 className="w-full text-left font-bold mt-4 mb-2">
                  {t("terms_of_service_eng.6")}
                </h3>
                {content_6_eng.map((item) => (
                  <p className="m-1 w-full text-left">{item}</p>
                ))}
                <h3 className="w-full text-left font-bold mt-4 mb-2">
                  {t("terms_of_service_eng.7")}
                </h3>
                {content_7_eng.map((item) => (
                  <p className="m-1 w-full text-left">{item}</p>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Terms;
