import { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import ScrollToTop from "./components/scroll-to-top";
import ThemeContext from "./context/theme";
import useLocalStorage from "./hooks/useLocalStorage";
import Business from "./pages/business-page/business-page";
// pages
import HomePage from "./pages/home-page/home-page";

import Privacy from "./pages/privacy-policy-page/privacy-policy-page";
import ResetPassword from "./pages/reset-password/reset-password";
import SignUpPage from "./pages/sign-up-page/sign-up";
import Terms from "./pages/terms-of-service-page/terms-of-service";

import { getToken } from "./helper/localstorage";
import { useUserValue } from "./context/UserContext";
import PurchaseCourse from "./components/Purchase";
import Login from "./pages/login-page/login-page";
import RegisterSuccess from "./components/RegisterSuccess";
import ForgotPassword from "./components/ForgotPassword";

const themes = {
  light: {
    background: "#fff",
    text: "#000",
    border: "#F2F2F2",
    innerBorder: "#FFFFFF",
    accordionBG: "#F2F2F2",
    accordionBtnBG: "#F2F2F2",
    accordionArrow: "#191919",
  },
  dark: {
    background: "#000000",
    text: "#fff",
    border: "#131313",
    innerBorder: "#212121",
    accordionBG: "#131313",
    accordionBtnBG: "#191919",
    accordionArrow: "#fff",
  },
};

function App() {
  const [theme, setTheme] = useState(themes.light);
  const [localTheme, setLocalTheme] = useLocalStorage("isDark", false);

  useEffect(() => {
    if (!localTheme) {
      changeTheme(themes.light);
      return;
    }

    changeTheme(themes.dark);
  }, []);

  const changeTheme = (theme) => {
    setTheme(theme);
  };
  const token = getToken();
  const isAuthenticated = token ? true : false;
  const [state] = useUserValue();
  useEffect(() => {}, [state.isAuthenticated]);

  return (
    <ThemeContext.Provider
      value={{
        currentTheme: theme,
        changeTheme: changeTheme,
        themes,
      }}
    >
      <div className="flex flex-col justify-center align-center w-full h-full">
        <ScrollToTop>
          <Routes>
            <Route
              path="(/forgot-password|/login|/reset-password|/subscribe|/signup|/reset|/success)"
              render={() => {
                if (isAuthenticated) return <Navigate to="/" />;
              }}
            />
            <Route path="/" element={<HomePage />} />
            <Route
              path="/login"
              element={
                isAuthenticated ? <Navigate to="/purchase" /> : <Login />
              }
            />
            <Route path="/reset" element={<ResetPassword />} />
            <Route path="/business" element={<Business />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route
              path="/signup"
              element={
                isAuthenticated ? <Navigate to="/purchase" /> : <SignUpPage />
              }
            />
            <Route path="/success" element={<RegisterSuccess />} />
            <Route
              path="/forgot-password"
              element={
                isAuthenticated ? (
                  <Navigate to="/purchase" />
                ) : (
                  <ForgotPassword />
                )
              }
            />

            <Route
              path="/purchase"
              element={
                isAuthenticated ? <PurchaseCourse /> : <Navigate to="/login" />
              }
            />
          </Routes>
        </ScrollToTop>
      </div>
    </ThemeContext.Provider>
  );
}

export default App;
