import { Link } from "react-router-dom";
import backgroundImage from "../../assets/images/background-image.png";
import Navbar from "../../components/navbar-components/navbar";
import { t } from "i18next";

const ResetPassword = () => {
  return (
    <>
      <Navbar />
      <img
        src={backgroundImage}
        alt="background"
        className="w-full absolute top-0 left-0 h-full object-cover z-[-1]"
      />
      <div className="w-full h-full flex flex-col items-center justify-center">
        <div className="w-full h-full flex flex-col items-center justify-center absolute top-0 left-0 text-white">
          <div className="relative">
            <div className="sm:w-[50vh] w-[22em] sm:h-[360px] h-[21em] bg-black opacity-70 flex flex-col items-center justify-center rounded-xl" />
            <div className="absolute top-5 sm:right-20 right-[10%] sm:w-2/3 w-[80%] h-full">
              <div className="text-3xl w-full mt-5 flex items-center text-center justify-center">
                {t("reset_password.resetPassword")}
              </div>
              <form className="flex flex-col w-full h-1/7 mt-10 items-center justify-center">
                <input
                  type="email"
                  placeHolder="First Name"
                  className="w-full h-14 px-4 rounded-2xl bg-[#333333] border-[#505050] placeholder-[#ffffff70]"
                />
                <span className="w-full mt-4">
                  <div className="flex flex-nowrap rounded-2xl text-white cursor-pointer h-14 bg-[#007DFF]">
                    <span className="w-full h-full flex items-center justify-center">
                      {t("reset_password.sendEmail")}
                    </span>
                  </div>
                </span>
                <div className="w-full flex mt-5 text-sm font-light">
                  {t("reset_password.rememberPassword")}?
                  <span className="text-[#007DFF] ml-2 underline cursor-pointer">
                    <Link to={"/login"} className="underline cursor-pointer">
                      {t("reset_password.logIn")}
                    </Link>
                  </span>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
